import { SxProps } from '@mui/material';

import { TRating, TReview } from '@/types';

export interface IStateObject {
	hasMore: boolean;
	showDialogOrDrawer: boolean;
}

export interface IReviewListProps {
	facilityName?: string;
	mobileWidth: boolean;
	reviews: Array<TReview>;
	filteredReviews?: Array<TReview>;
	filteredReviewsTitle?: string;
}

export interface IReviewListStateObject {
	displayedReview: TReview | null;
	showDialog: boolean;
}

export interface IReviewBarsProps {
	ratings: Array<TRating>;
	reviewCount: number;
}

export interface IOneReviewProps {
	review: TReview;
	showFullReview?: boolean;
	sx?: SxProps;
	dataTestId: string;
}

export interface IReviewsDialogOrDrawer {
	facilityName: string | undefined;
	handleCloseDialog: () => void;
	handleLoadMore?: () => void;
	hasMore?: boolean;
	mobileWidth: boolean;
	reviews: TReview[];
	showFullReview?: boolean;
}

export const SCHEMA_REVIEWS_LIMIT = 50;

// max rating is always 5
export const FILTERED_REVIEWS_MAX_RATING = 5;

// need to show only 3 in UI
// some reviews may not have comments
// so fetching 10 and getting 3 with the comments to be safe
export const FILTERED_REVIEWS_TAKE = 10;
