import React, { useEffect, useState } from 'react';

import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import Head from 'next/head';
import { format } from 'date-fns';

import AspectRatioPaddingBox from '@/components/AspectRatioPaddingBox';
import VideoContent from '@/components/VideoContent';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import { useAppContext, useFacilityContext, useOfficeLocationContext } from '@/context';
import { BffBrandType, Video, BirdEyeReviews as BirdEyeReviewsType } from '@/types/generated';
import { HeadingTag, TReview } from '@/types';
import TAGLink from '@/components/TAGLink';
import { getBffReviewsSelection } from '@/services/getBffReviewsSelection';

import FocalPointImage from '../FocalPointImage';

import { FILTERED_REVIEWS_MAX_RATING, FILTERED_REVIEWS_TAKE, IStateObject, SCHEMA_REVIEWS_LIMIT } from './BirdEyeReviews.helper';
import ReviewsDialogOrDrawer from './ReviewsDialogOrDrawer';
import ReviewList from './ReviewList';
import ReviewBars from './ReviewBars';
import Rating from './Rating';
import {
	ratingBlockAndBarsBoxStyles,
	getWrapperStyles,
	mediaBoxStyles,
	reviewsBlockBoxStyles,
	templateBodyStyles,
	templateTitleStyles,
	ratingBlockBoxStyles,
	avgRatingTypographyStyles,
	pointerStyles,
	noOfReviewsLinkStyles,
} from './BirdEyeReviews.styles';

export interface IBirdEyeReviews extends Partial<BirdEyeReviewsType> {
	/**
	 * Boolean specifying to render only stars and link vs full component with media and review bars.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IBirdEyeReviews
	 */
	onlyStarsAndLink?: boolean;
}

export default function BirdEyeReviews({
	mediaContent,
	title,
	titleHeading = 'h2',
	onlyStarsAndLink,
	filteredReviewsMinRating,
	filteredReviewsTitle
}: Readonly<IBirdEyeReviews>) {
	const [state, setState] = useState<IStateObject>({
		showDialogOrDrawer: false,
		hasMore: false,
	});

	const theme = useTheme();
	const mobileWidth = useMediaQuery(theme.breakpoints.down('md'));
	const [filteredReviews, setFilteredReviews] = useState<(TReview)[]>([]);
	const { environment, config, appName, appVersion } = useAppContext();
	const { reviews, officeInfo } = useOfficeLocationContext();
	const { facilityBrand } = useFacilityContext();
	const { showDialogOrDrawer, hasMore } = state;

	const filteredReviewsFromBff = async () => {
		const url =
			environment === 'prod' ? config.services.prod.BEFFE_GRAPHQL_URL : config.services.nonprod.BEFFE_GRAPHQL_URL;
		
		const filteredReviews = await getBffReviewsSelection(
			facilityBrand.toString() as BffBrandType,
			officeInfo.code,
			Number(filteredReviewsMinRating),
			FILTERED_REVIEWS_MAX_RATING,
			FILTERED_REVIEWS_TAKE,
			url,
			{
				appName,
				appVersion
			}
		)

		const top3ReviewsWithComments = filteredReviews?.reviewsSelection?.filter(review => review?.comments !== null)?.slice(0, 3)
		setFilteredReviews(top3ReviewsWithComments as Array<TReview>);
	};

	useEffect(() => {
		if (filteredReviewsMinRating) {
			void filteredReviewsFromBff();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredReviewsMinRating]);

	const handleCloseDialog = () => {
		setState({
			...state,
			showDialogOrDrawer: false,
		});
	};

	const handleOpenDialog = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
		e.preventDefault();
		setState({
			...state,
			showDialogOrDrawer: true,
		});
	};

	const ReviewsBlock = () => {
		return (
			<Box data-test-id="ReviewsBlock_Box" sx={{ ...reviewsBlockBoxStyles(mobileWidth) }}>
				<Box sx={{ ...ratingBlockAndBarsBoxStyles }}>
					<Box sx={{ ...ratingBlockBoxStyles }}>
						<Typography
							variant="bodyXLBook"
							color="text.primary"
							sx={{ ...avgRatingTypographyStyles }}
							data-test-id="ReviewsBlock_AvgRating"
						>
							{reviews.averageRating}
						</Typography>
						<Rating rating={reviews.averageRating} />
						<Typography
							variant="textLinkSmall"
							onClick={handleOpenDialog}
							data-test-id="ReviewsBlock_Reviews_Length"
							sx={{ ...pointerStyles }}
						>
							{reviews.reviewCount.toString() + ' Reviews'}
						</Typography>
					</Box>
					<ReviewBars ratings={reviews.ratings} reviewCount={reviews.reviewCount} />
				</Box>
				<ReviewList
					facilityName={officeInfo?.name}
					mobileWidth={mobileWidth}
					reviews={reviews.reviews}
					filteredReviews={filteredReviews}
					filteredReviewsTitle={filteredReviewsTitle ?? ''}
				/>
			</Box>
		);
	};

	if (!Object.keys(reviews).length || !reviews?.reviews) {
		return null;
	}

	return (
		<>
			<Head>
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify({
							'@context': 'https://schema.org/',
							'@type': 'LocalBusiness',
							name: config.name,
							aggregateRating: {
								'@type': 'AggregateRating',
								ratingValue: reviews.averageRating && Number(reviews.averageRating.toFixed(1)),
								reviewCount: reviews.reviewCount,
							},
							review: reviews.reviews.slice(0, SCHEMA_REVIEWS_LIMIT).map((review: TReview) => {
								const reviewTypeSchema = {
									'@type': 'Review',
									author: {
										'@type': 'Person',
										name: review.reviewer.firstName,
									},
									datePublished: format(new Date(review.reviewTimestamp), 'yyyy-MM-dd'),
									reviewRating: {
										'@type': 'Rating',
										ratingValue: review.rating,
										worstRating: 0,
										bestRating: 5,
									},
								};
								if (review.comments) {
									return {
										...reviewTypeSchema,
										reviewBody: review.comments,
									};
								}
								return reviewTypeSchema;
							}),
						}),
					}}
				/>
			</Head>

			{onlyStarsAndLink ? (
				<Box
					display="flex"
					alignItems="center"
					gap=".5rem"
					data-test-id="BirdEye_Reviews_Box_Only_Stars_And_Link"
				>
					<Rating rating={reviews.averageRating} />
					<TAGLink onClick={handleOpenDialog} linkSx={noOfReviewsLinkStyles}>
						{reviews.reviewCount.toString() + ' Reviews'}
					</TAGLink>
				</Box>
			) : (
				<Box sx={{ ...getWrapperStyles(mobileWidth) }} data-test-id="BirdEye_Reviews_Box">
					{title && (
						<Box sx={{ ...templateTitleStyles(mobileWidth) }}>
							<Typography
								variant={getHeadingVariant(titleHeading as HeadingTag)}
								color="text.primary"
								data-test-id="BirdEye_Reviews_Box_Title"
							>
								{title}
							</Typography>
						</Box>
					)}

					<Box sx={{ ...templateBodyStyles(mobileWidth) }}>
						<Box sx={{ ...mediaBoxStyles(mobileWidth) }}>
							<AspectRatioPaddingBox aspectRatio="16:9">
								{mediaContent && mediaContent?.__typename ? (
									mediaContent.__typename === 'FocalPointImage' ? (
										<FocalPointImage
											{...mediaContent}
											dataTestId={`media_focal_image_${mediaContent.sys.id}`}
										/>
									) : (
										<VideoContent
											dataTestId={`media_video_${mediaContent && mediaContent.sys.id}`}
											controllableVideo={mediaContent as Video}
											sixteenByNineVideoRatio={true}
										/>
									)
								) : null}
							</AspectRatioPaddingBox>
						</Box>
						<ReviewsBlock />
					</Box>
				</Box>
			)}

			{showDialogOrDrawer && (
				<ReviewsDialogOrDrawer
					mobileWidth={mobileWidth}
					handleCloseDialog={handleCloseDialog}
					reviews={reviews.reviews}
					hasMore={hasMore}
					facilityName={officeInfo?.name}
					handleLoadMore={() => null}
				/>
			)}
		</>
	);
}
