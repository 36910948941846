import { getGraphQLClient } from "@/graphql/client";
import { getGraphHeaders } from '@/utils/getGraphHeaders';
import { logWarning } from '@/utils/miscUtils';
import { BffBrandType, BffReviewsSelectionQuery } from '@/types/generated';

export const getBffReviewsSelection = async (
	brand: BffBrandType,
	facilityCode: string,
	min: number,
	max: number,
	take: number,
	url: string,
	appDetails: {
		appName: string,
		appVersion: string
	}
) => {
    try {
        return await getGraphQLClient(url).bffReviewsSelection({
			brand,
			facilityCode,
			min,
			max,
			take,
		},
        getGraphHeaders(appDetails.appName, appDetails.appVersion))
    } catch (err) {
		logWarning(`MISSING_SELECTED_REVIEWS: ${brand} for query: take = ${take}`);
		return {
			reviewsSelection: []
		} as BffReviewsSelectionQuery
	}
	
};